(function($) {

    'use strict';

    var Modal = function(element, options) {
        this.$element = $(element);
        this.options  = options;
        this.header   = this.$element.find('.modal-header');
        this.init();
    };

    Modal.DEFAULTS = {
        className: null,
        innerClassName: null,
        show: true,
        fullscreen: false,
        shouldResize: false,
        redirectCloseModal: null
    };

    Modal.prototype.init = function() {
        if (this.$element.prop("tagName") == "BODY") {
            this.$element = $(template);
            if (this.options.content) {
                this.$element.find('.modal-container').html(this.options.content);
            }
            $('body').append(this.$element);
        }

        if (this.options.modalContent) {
            this.addContentToModal($(this.options.modalContent).html());
        }

        if (this.options.remote) {
            if (this.$element.prop("tagName") == 'BODY') {
                this.$element = $(template);
                this.$element.appendTo('body');
            }
            this.$element
                .highlight('open')
                .find('.modal-container')
                .load(this.options.remote, $.proxy(this, 'loaded'));
        }

        this.$element.addClass(this.options.className);
        this.$element.children().addClass(this.options.innerClassName);
        this.$element.toggleClass('is-fullscreen', this.options.fullscreen);

        this.$element.attr({
            tabIndex: -1,
            role: 'dialog'
        });

        this.$element.on('click', '[data-dismiss=modal]', $.proxy(this, 'close'));
    };

    Modal.prototype.addContentToModal = function(content) {
        this.open();
        this.$element.find('.modal-container').append(content);
    };

    Modal.prototype.toggle = function() {
        this.isOpen() ? this.close() : this.open();
    };

    Modal.prototype.isOpen = function() {
        return this.$element.is('.is-open');
    };

    Modal.prototype.open = function() {
        if (this.$element.hasClass('modal-2-fade')) {
            this.$element.addClass('modal-2-fade-active');
        }

        $('body').addClass('is-modal-open');
        this.$element.addClass('is-open').highlight('open');
        this.$element.bind('keyup', $.proxy(this, 'keyup'));

        this.$element.trigger('focus');
        this.enforceFocus();

    };

    Modal.prototype.close = function() {

        $(document).off('focusin.dft.modal');
        this.$element.unbind('keyup');

        this.$element.trigger('close');
        $('.carousel-item.hidden').removeClass('hidden');

        var self = this;
        self.$element.removeClass('is-open');
        setTimeout(function() {
            self.$element.highlight('close');
        }, 600);

        if (this.$element.hasClass('modal-2-fade')) {
            var el = this.$element;
            setTimeout(function() {
                $('body').removeClass('is-modal-open');
                el.removeClass('modal-2-fade-active');
                el.find('.slideshow-item').removeClass('is-active is-inactive');
            }, 500);
        } else {
            $('body').removeClass('is-modal-open');
            $(document).trigger('modal.' + this.options.innerClassName + '.closed');
        }

        this.redirectOnCloseModal();
    };

    Modal.prototype.keyup = function(e) {
        var code = e.keyCode || e.which;
        if (code == 27) {
            this.close();
            e.preventDefault();
        }

        this.redirectOnCloseModal();
    };

    Modal.prototype.redirectOnCloseModal = function () {
        if (this.options.redirectCloseModal) {
            window.location.href = window.location.origin + this.options.redirectCloseModal;
        }
    };

    Modal.prototype.enforceFocus = function() {
        $(document)
            .off('focusin.dft.modal')
            .on('focusin.dft.modal', $.proxy(function(e) {
                if (this.$element[0] !== e.target && !this.$element.has(e.target).length) {
                    this.$element.trigger('focus');
                }
            }, this));
    };

    Modal.prototype.loaded = function() {
        this.open();
        this.$element.trigger('modal.' + this.options.innerClassName);
    };

    Modal.prototype.destroy = function() {
        this.$element.removeData('dft.modal');
        $(this.options.trigger).removeData('dft.target');
        this.$element.remove();
    };

    $.fn.modal = function(option) {
        return this.each(function() {

            var $this = $(this);
            var data = $this.data('dft.modal');
            var options = $.extend({}, Modal.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.modal', (data = new Modal(this, options)));
            if (typeof option == 'string') data[option]();
        });
    };

    var template = '<div class="modal" data-modal data-dismiss="modal">' +
        '<div class="modal-container col-xs-12"></div>' +
        '</div>';

    $(document).on('click', '[data-toggle=modal]', function(e) {
        var $this = $(this);
        var href = $this.attr('href');
        var $target = $($this.data('dft.target') || $this.attr('data-target'));
        
        var option = $target.data('dft.modal') ? 'toggle' : $.extend({
            remote: !/#/.test(href) && href,
            shouldResize: $target.data('resize'),
            trigger: $this
        }, $this.data());

        if (!$target.length) {
            $target = $(template).appendTo('body');
        }

        $this.data('dft.target', $target.get(0));

        if ($('body').hasClass('mobile') && ($target.data('hide-mobile') || $this.data('hide-mobile'))) {
            return;
        }

        e.preventDefault();

        $target.modal(option).on('close', function() {
            $this.is(':visible') && $this.trigger('focus');
        });

        if (typeof $this.data('modal-no-cache') !== 'undefined') {
            $target.data('dft.modal', false);
            $this.data('dft.target', false);
        }
    });

    $('.mobile [data-auto-open="modal"]:not([data-hide-mobile])').modal();
    $('.desktop [data-auto-open="modal"], .tablet [data-auto-open="modal"]').modal();

})(jQuery);
