(function($) {

    'use strict';

    var CartPreview = function(element, options) {
        this.options = options;
        this.$element = $(element);

        this.init();
    };

    CartPreview.DEFAULTS = {
        count: 0,
        jsonUrl: null,
        loaded: false
    };

    CartPreview.prototype.setReload = function(qty){
        if (typeof qty === "number") {
            this.options.count = qty;
        }

        this.options.loaded = false;
    };

    CartPreview.prototype.reload = function() {
        this.loaded = false;
        this.resetItens();
        this.init();
    };

    CartPreview.prototype.init = function() {
        if ( !this.options.loaded ) {
            this.getJson().success($.proxy(this, "finishJson"));
        }
    };

    CartPreview.prototype.getJson = function() {
        return $.ajax({
                global: false,
                dataType: 'json',
                url: this.options.jsonUrl
            });
    };

    CartPreview.prototype.finishJson = function(data) {
        this.options.loaded = true;
        this.data          = data;
        this.updateItemCount(data.length-1);
        this.fillFields();
    };

    CartPreview.prototype.updateItemCount = function(number) {
        if(typeof number === "undefined")   number = this.options.count;
        if(number <= 0)                     number = "";
        this.options.count = number;
        this.$element.find('[data-field=item-count]').text(number);
    };

    CartPreview.prototype.fillFields = function() {
        var subtotal = 0.00;
        for(var i = 1; i <= 3; i++){
            if(typeof this.data[i] !== "undefined") {
                this.addItem(this.data[i]);
                subtotal += this.data[i].price;
            }
        }

        this.$element.find('[data-field=subtotal]').text(_formatMoney(subtotal));

        if (this.$element.find('[data-type=item]').length > 1)
            this.$element.find('[data-type=item]').first().remove();
    };

    CartPreview.prototype.addItem = function(data) {
        var newItem = this.$element.find('[data-type=item]').last().clone();
        var special_price = _formatMoney(data.special_price);

        this.resetItem(newItem);

        newItem.find('[data-field=image]').attr('src', data.image);
        newItem.find('[data-field=name]').text(data.name);
        newItem.find('[data-field=size]').text(data.size);
        newItem.find('[data-field=color]').text(data.color);
        newItem.find('[data-field=special_price]').text(special_price).parent().toggleClass('is-active',special_price !== null);
        newItem.find('[data-field=price]').text(_formatMoney(data.price));
        newItem.find('[data-field=installment]').text(data.installment);
        newItem.find('[data-sku]').attr("data-sku", data.sku);

        this.$element.find('[data-type=item]').last().after(newItem);

        $(document).trigger('dft.cart-preview.addItem');
    };

    CartPreview.prototype.resetItem = function(itemEl) {
        itemEl.find('[data-field]').text('');
    };

    CartPreview.prototype.resetItens = function() {
        while(this.$element.find('[data-type=item]').length > 1){
            this.$element.find('[data-type=item]')[0].remove();
        }
        this.$element.find('[data-type=item]').find('[data-field]').text('');
    };

    var _formatMoney = function(value) {
        if(typeof value === 'number'){
            return value.toFixed(2).replace('.',',');
        } else {
            return null;
        }
    };

    $.fn.cartPreview = function(option,args) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('dft.cart-preview');
            var options = $.extend({}, CartPreview.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.cart-preview', (data = new CartPreview(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).ready(function() {
        var url = window.secureUrl || '';

        url += '/cart/index/indexJson/?callback=?&freight=true';

        if (window.dft.urlParam('tp')) {
            url += '&tp=' + window.dft.urlParam('tp');
        }

        $('.cart-preview').cartPreview({count: $.cookie('cart_count'), jsonUrl: url});
    });

})(jQuery);
