window.dft.SocialId = function() {
    var socialId = null;

    var socialFields = { fields: "providers, display_name, verified_email, current_location, picture_url, name, birthday, gender" };

    var defaultPictureUrl = 'https://dafitistatic.dafiti.com.br/dynamic_yield/cms/static/kanui/images/29c7ebde1fdff__avatar100.png';

    $(function() {

        var socialModalOption = false;
        if (typeof(window.socialid) !== "object") window.socialid = {};

        //socialid.debugMode = true;

        socialid.onLoad = function() {
            socialid.login.init(183, { loginType: "event" } );

            if ($('.api-buttons').length > 0) {
                eventHandlers();
                socialid.login.getUserInfo();

            } else if ($('.social-info').length > 0) {
                eventHandlers();
                socialid.login.getUserInfo();
            } else if ($('.social-edit-content').length > 0) {
                socialid.login.getUserInfo(connectionHandler, socialFields);
                eventHandlersConnect();
            } else if ($('#socialid').length > 0){
                /*checkSocialAvailability();*/
                connectOutSocialId();
            }
            $(document).trigger('socialid-onload');
        };

        if (($('body').data('route') == 'checkout/index/index')||
            ($('body').data('route') == 'customer/account/login')||
            ($('body').data('route') == 'customer/account/home')||
            ($('body').data('route') == 'customer/account/list')||
            ($('body').data('route') == 'customer/account/edit')) {
            socialid.onLoad();
        }

        $(document).on('click', '.user-logout', function(e) {
            socialid.login.logout();

            setCookie('social_pic', null);
            $('.header-login').headerLogin('setPicture', null);

            $.ajax({
                url: '/customer/social/unsetUserInfo/',
                type: 'GET',
                success: function(data) {
                    window.location = "/customer/account/logout/";
                }
            });

        });

        if ((getCookie('customer_logged') != '1')&&(getCookie('customer_logged') != null)) {
            socialid.login.logout();
        }

        if (getCookie('social_dup') == '1') {
            $('body').modal({
                remote: '/customer/social/SocialAlreadyConnected/?error=2'
            });
        }
    });

    function setSocialOption() {
        socialModalOption = $("#dont_ask_about_social_networks").is(':checked');
    }

    function setCookie(c_name,value,exdays) {
        $.cookie(c_name, value, {expires: exdays , path:'/'});
    }

    function getCookie(c_name) {
        return $.cookie(c_name);
    }

    function submitSocialOption() {
        if (socialModalOption == true) {
            $.ajax({
                url: '/customer/social/noToSocialNetwork/',
                type: 'GET'
            });
        }
    }

    /*function checkSocialAvailability() {
        $.ajax({
            url: '/customer/social/checkSocialAvailability/',
            type: 'GET',
            success: function(data) {
                if (jQuery.parseJSON(data) == true){
                    socialid.login.getUserInfo(logonHandler);
                }
            }
        });
    }*/

    function eventHandlers() {
        var ts = Math.round((new Date()).getTime() / 1000);
        var fullpage = location.pathname;
        var page = fullpage.split("/");
        var params = location.search;
        var bootstrap = '';

        if (location.pathname.indexOf('checkout') > 0) {
            bootstrap = 'b2014=1&';
        }

        socialid.events.onLoginSuccess.addHandler(function(data) {
            setCookie('chrono_social', ts , 1);
            $.ajax({
                url: '/customer/social/getUserInfo/',
                data: {token: data.token},
                dataType: 'json',
                type: 'GET',
                beforeSend: function() {
                    $('.social-overlay').prependTo($('body')).show();
                },
                complete: function() {
                    $('.social-overlay').hide();
                },

                success: function(data) {
                    if (data.customer_id) {
                        if (page[1] == 'customer'){
                            window.location = '/customer/account/home/';
                        } else if (page[1] == 'checkout') {
                            window.location = '/checkout/index/redirectCheckout';
                        }
                    } else {
                        if (page[1] == 'customer') {
                            activateSocialLoginPage(data);
                            fillRegistrationForm(data);
                        } else if (page[1] == 'checkout') {
                            $('body').modal('destroy');
                            $('body').removeData('dft.modal');
                            $('body').modal({
                                className: 'socialLoginModal',
                                remote: '/customer/social/associate/?'+ bootstrap +'thm=checkout'
                            });
                            $('.socialLoginModal').on('close', function() {
                                unassociatedUserLogout();
                            });
                        }
                    }
                }
            });
        });
    }

    function eventHandlersConnect() {
        socialid.events.onLoginSuccess.addHandler(function(data) {
            $.ajax({
                url: '/customer/social/connectUser/',
                data: {token: data.token},
                type: 'GET',
                success: function(data) {
                    if (data != 'true') {
                        socialId = data;
                        socialid.login.logout(disconnectIfErrorHandler);
                    } else {
                        socialid.login.getUserInfo(connectionHandler, socialFields);
                        socialid.login.getUserInfo(pictureHandler, socialFields);
                    }
                }
            });
        });

        socialid.events.onConnectSuccess.addHandler(function(data) {
            socialid.login.getUserInfo(connectionHandler, socialFields);
            socialid.login.getUserInfo(pictureHandler, socialFields);
        });

        socialid.events.onConnectError.addHandler(function(data) {
            refreshWidget();
            generateConnectionErrorModal();
        });

        socialid.events.onDisconnectSuccess.addHandler(function(data) {
            socialid.login.getUserInfo(disconnectHandler);
        });
    }

    function disconnectIfErrorHandler(response) {
        if (response.status == "success") {
            refreshWidget();
            generateConnectionErrorModal();
        }
    }

    function refreshWidget() {
        socialid.login.getUserInfo(pictureHandler, socialFields);
    }

    function generateConnectionErrorModal() {
        setCookie('social_pic', null);
        $('.header-login').headerLogin('setPicture', null);
        $('body').modal({
            remote: '/customer/social/SocialAlreadyConnected/?error=1'
        });
    }

    function logonHandler(response) {
        if (response.status != 'success'){
            if ((getCookie('social_helper') != 'loaded')) {
                eventHandlersConnect();

                $('body').modal('destroy');
                $('body').removeData('dft.modal');
                $('body').modal({
                    remote : '/customer/account/SocialModal/',
                    callbackSuccess : function(){
                        setCookie('social_helper', 'loaded', 1);
                        socialid.login.renderConnectWidget("connect-social", {
                            theme: "labeled_icons",
                            providers: ["facebook", "twitter", "apple"],
                            language: "pt_br",
                            showSocialIdLink: false
                        });
                    }
                });

                $('.cl-generalModal').attr('onClick','submitSocialOption()');
            }
        }
    }

    function unassociatedUserLogout() {
        $.ajax({
          url: '/customer/social/unsetUserInfo/',
          type: 'GET',
          success: function(data) {
            setCookie('social_pic', null);
            $('.header-login').headerLogin('setPicture', null);

            socialid.login.logout();
          }
        });
    }

    function disconnectHandler(response) {
        if (response.data) {
            setCookie('social_pic', null);
            $('.header-login').headerLogin('setPicture', null);
            if ($('.my-account-avatar-image').length > 0) {
                $('.my-account-avatar-image').removeAttr('src');
                $('.my-account-menu-social').removeClass('has-social-picture');
            }

            socialid.login.logout();
            $.ajax({
                url: '/customer/social/disconnectUser/',
                type: 'GET'
            });
        }
    }

    function pictureHandler(response) {
        if (response.data && response.data.profile && response.data.profile.providers) {
            var providers = response.data.profile.providers
            var pictureUrl = Object.values(providers)[0].picture_url;

            if (!pictureUrl) {
                pictureUrl = defaultPictureUrl
            }

            $('.header-login').headerLogin('setPicture', pictureUrl);
            if ($('.my-account-avatar-image').length > 0) {
                $('.my-account-avatar-image').attr('src', pictureUrl);
            }
            setCookie('social_pic', pictureUrl, 1);
        } else {
            $('.header-login').headerLogin('setPicture', null);
            setCookie('social_pic', null);
            if ($('.my-account-avatar-image').length > 0) {
                $('.my-account-avatar-image').removeAttr('src');
                $('.my-account-menu-social').removeClass('has-social-picture');
            }
        }
    }

    function connectionHandler(response) {
        if (response.data && response.data.profile && response.data.profile.providers) {
            var providers = response.data.profile.providers
            var provider = Object.keys(providers)[0];
            var pictureUrl = Object.values(providers)[0].picture_url;
            if (!pictureUrl) {
                pictureUrl = defaultPictureUrl
            }

            $('.myaccount-avatar.' + provider).addClass('is-connected');
            $('.myaccount-photo.' + provider).attr('src', pictureUrl);
        }
    }

    function connectOutSocialId() {
        $.ajax({
            url: '/customer/social/connectOutSocialId/',
            type: 'GET',
            success: function(data) {
                data = jQuery.parseJSON(data);
                socialId = data.social_id;
                if (typeof data.social_id !== 'undefined' && typeof data.user_token !== 'undefined') {
                    socialid.login.loginUserToken(data.social_id, data.user_token, userTokenHandler);
                }
            }
        });
    }

    function userTokenHandler(response) {
        if (response.status == "not_found") {
            /*if (socialId == null) {
                checkSocialAvailability();
            } else {*/
            if (socialId != null) {
                $.ajax({
                    url: '/customer/social/recoverSocialUserToken/',
                    data: {social_id: socialId},
                    type: 'GET',
                    success: function(data) {
                        if (typeof data.social_id !== 'undefined' && typeof data.user_token !== 'undefined') {
                            /*socialid.login.loginUserToken(socialId, data , recoveredUserTokenHandler);*/
                            socialid.login.loginUserToken(socialId, data);
                        }
                    }
                });
            }
        } else {
            socialid.login.getUserInfo(connectionHandler, socialFields);
            socialid.login.getUserInfo(pictureHandler, socialFields);
            /*checkSocialAvailability();*/
        }
    }

    function disconnectFacebook() {
        socialid.login.disconnect("facebook");
        $('.myaccount-avatar.facebook').removeClass('is-connected');
    }

    function disconnectTwitter() {
        socialid.login.disconnect("twitter");
        $('.myaccount-avatar.twitter').removeClass('is-connected');
    }

    function disconnectApple() {
        socialid.login.disconnect("apple");
        $('.myaccount-avatar.apple').removeClass('is-connected');
    }

    function disconnectGplus() {
        socialid.login.disconnect("gplus");
        $('.myaccount-avatar.gplus').removeClass('is-connected');
    }

    function activateSocialLoginPage(data) {
        if (data.profile && data.profile.providers) {
            var providers = data.profile.providers
            var pictureUrl = Object.values(providers)[0].picture_url;

            if (!pictureUrl) {
                pictureUrl = defaultPictureUrl
            }

            // hide social user name when we do not have one
            var provider = Object.keys(providers)[0];
            if (provider === 'apple') {
                $('.with-social-user-name').addClass('hide')
                $('.no-social-user-name').removeClass('hide')
            }

            $('[data-social-user-name]').text(data.profile.display_name);
            $('[data-social-user-network]').text(data.credential.type);
            $('[data-social-user-image]').attr('src', pictureUrl);
            $('.interstitial-login-separator').addClass('socialActive');
            $('.social-interface').addClass('socialActive');
            $('.middle-separator').addClass('hide');
            $('.social-buttons').addClass('hide');
            $('.social-user-info').removeClass('hide').delay(300).css({ opacity: 0, filter: '' }).animate({ opacity: 1, filter: '' });
        }
    }

    function fillRegistrationForm(data) {
        $('[data-social-first-name]').val(data.profile.name.given_name);
        $('[data-social-last-name]').val(data.profile.name.middle_name + ' ' + data.profile.name.family_name);
        $('[data-social-gender]').val(data.profile.gender);

        var nowDate = new Date();
        var fullBirthday = data.profile.birthday || nowDate.getDate() + "-" + nowDate.getMonth() + "-" + nowDate.getFullYear();
        var birthDate = fullBirthday.split('-');

        $('[data-social-birth-day]').val(birthDate[2]);
        $('[data-social-birth-month]').val(birthDate[1]);
        $('[data-social-birth-year]').val(birthDate[0]);
    }

    function deactivateSocialLoginPage() {
        $('.social-user-info').addClass('hide');
        $('.social-buttons').removeClass('hide').delay(300).css({ opacity: 0, filter: '' }).animate({ opacity: 1, filter: '' });
        $('.middle-separator').removeClass('hide');
        $('.social-interface').removeClass('socialActive');
        $('.social-interface-title').removeClass('hide');
        $('.interstitial-login-separator').removeClass('socialActive');
    }

    function unfillRegistrationForm() {
        $('[data-social-first-name]').val('');
        $('[data-social-last-name]').val('');
        $('[data-social-gender]').val('');
        $('[data-social-birth-day]').val('');
        $('[data-social-birth-month]').val('');
        $('[data-social-birth-year]').val('');
    }

    /*function recoveredUserTokenHandler(response) {
        if (response.status == "success") {
            checkSocialAvailability();
        }
    }*/

    window.unassociatedUserLogout = unassociatedUserLogout;
    window.setSocialOption        = setSocialOption;
    window.submitSocialOption     = submitSocialOption;
    window.getCookie              = getCookie;
    window.eventHandlers          = eventHandlers;
    window.checkLoginState = checkLoginState;
    window.checkLoginStateToAssociate = checkLoginStateToAssociate;
    window.loginSocial = loginSocial;
    window.socialDisconnect = socialDisconnect;
    window.associateLogged = associateLogged;
    window.facebookLogout = facebookLogout;

    $(document).on("click", "[data-facebook-disconnect=true]", function(e) {
        disconnectFacebook();
    });

    $(document).on("click", "[data-twitter-disconnect=true]", function(e) {
        disconnectTwitter();
    });

    $(document).on("click", "[data-apple-disconnect=true]", function(e) {
        disconnectApple();
    });

    $(document).on("click", "[data-gplus-disconnect=true]", function(e) {
        disconnectGplus();
    });

    $(document).ready(function(e) {
        if ($("[data-social-interface]").length > 0) {
            unassociatedUserLogout();
        }
    });

    $('[data-disconnect-social="true"]').on('click', function() {
        unassociatedUserLogout();
        unfillRegistrationForm();
        deactivateSocialLoginPage();
    });

    //new Social Login
    function checkLoginState() {
        FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                loginSocial(response.authResponse.accessToken, 'facebook')
            } else {
                FB.login(function (response) {
                    if (response.status === 'connected') {
                        loginSocial(response.authResponse.accessToken, 'facebook')
                    }
                });
            }
        });
    }

    function checkLoginStateToAssociate() {
        FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                associateLogged(response.authResponse.accessToken, 'facebook')
            } else {
                FB.login(function (response) {
                    if (response.status === 'connected') {
                        associateLogged(response.authResponse.accessToken, 'facebook')
                    }
                });
            }
        });
    }

    function facebookLogout() {
        FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                FB.logout(function (response) {
                });
            }
        });
        socialDisconnect('facebook')
    }

    function loginSocial(token, provider) {
        var fullpage = location.pathname;
        var page = fullpage.split("/");
        $('.social-overlay').show();
        $.ajax({
            url: '/customer/social/loginSocial/',
            data: {
                token: token,
                provider: provider,
            },
            dataType: 'json',
            type: 'GET',
            success: function (data) {
                $('.social-overlay').hide();
                if (data.code == '200') {
                    if(data.logged){
                        if (page[1] == 'customer') {
                            window.location = '/customer/account/home/';
                        } else if (page[1] == 'checkout') {
                            window.location = '/checkout/index/redirectCheckout';
                        }
                    }
                    if(data.assocciate){
                        $('[data-social-user-name]').text(data.result.profile.display_name);
                        $('[data-social-user-image]').attr('src', data.result.profile.picture_url);
                        $('.interstitial-login-separator').addClass('socialActive');
                        $('.social-interface').addClass('socialActive');
                        $('.middle-separator').addClass('hide');
                        $('.social-buttons').addClass('hide');
                        $('.social-user-info').removeClass('hide').delay(300).css({ opacity: 0, filter: '' }).animate({ opacity: 1, filter: '' });
                    }
                }
            }
        });
    }

    function associateLogged(token, provider) {
        $('.social-overlay').show();
        var _csrf = $('#YII_CSRF_TOKEN').val();
        $.ajax({
            url: '/customer/social/associateCustomer/',
            data: {
                token: token,
                provider: provider,
                _csrf: _csrf,
            },
            dataType: 'json',
            type: 'GET',
            success: function (data) {
                $('.social-overlay').hide();
                if (data.code == '200') {
                    window.location = "/customer/account/list/";
                } else {
                    window.location = "/customer/account/logout/";
                }
            }
        });
    }

    function socialDisconnect(provider) {
        $.ajax({
            url: '/customer/social/socialDisconnect/',
            data: {
                provider: provider,
            },
            dataType: 'json',
            type: 'GET',
            success: function (data) {
                window.location = "/customer/account/list/";
            }
        });
    }

};
window.dft.SocialId();