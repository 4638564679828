(function($) {

    'use strict';

    var CatalogDetailAction = function() {};
    CatalogDetailAction.prototype = new window.dft.ActionController();

    CatalogDetailAction.prototype.run = function() {
        $(document).trigger('dft.add-coupon');
        $(document).trigger('dft.fashion-selfie.load');
        $(document).trigger('dft.events-monitoring');

        $('[data-lazyload]').lazyload();
    };

    CatalogDetailAction.prototype.showSizeReminder = function() {
        $('#size-reminder-2-popover').popover('open');
        $('#form-size-not-found').css('display', 'flex');
    };

    $(document).on('click', '#size-reminder-2-popover .size-list-item a', function() {
        if (typeof(_gaq) != 'undefined') {
            _gaq.push(['_trackEvent', 'Product', 'Mais opcoes', $(this).data('name'), $(this).data('numeric-size'), true]);
        }
    });

    $(document).on('click', '.in-popup', function() {
        var targetUrl = $(this).attr("href");
        var network = $(this).parent().attr('class');
        if (typeof(_gaq) != 'undefined') {
            switch (network) {
                case 'twitter':
                    _gaq.push(['_trackSocial', 'twitter', 'tweet', targetUrl]);
                    beak;
                case 'facebook':
                    _gaq.push(['_trackSocial', 'facebook', 'share', targetUrl]);
                    break;
                case 'googleplus':
                    _gaq.push(['_trackSocial', 'googleplus', 'plusone', targetUrl]);
                    break;
                case 'pinterest':
                    _gaq.push(['_trackSocial', 'pinterest', 'pinned'], targetUrl);
                    break;
            }
        }

        window.open(targetUrl, 'social-network-window', 'height=250,width=500,scrollbars=1');
        return false;
    });

    routes['catalog/detail'] = function() {
        (new CatalogDetailAction()).run();
    };
    $(document).trigger('catalog/detail');

})(jQuery);
