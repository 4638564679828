(function($) {

    'use strict';

    var DeleteCreditCard = function(element, options) {
        this.$element = $(element);
        this.$parent = this.$element.closest('tr');
        this.name_card = this.$parent.find('.name').text();
        this.template = $('script[type="text/credit-card-undo-template"]').html();
        this.options = options;
        this.init();
    };

    DeleteCreditCard.DEFAULTS = {
        oldcc: '',
        ccId: -1,
        time_delete: 5000,
        stop_delete: false,
    };

    DeleteCreditCard.prototype.init = function() {
        this.deleteCreditCardNow();
    };

    DeleteCreditCard.prototype.deleteCreditCardNow = function() {
        $('body').modal({
            'modalContent': this.template,
            'innerClassName': 'modal-creditcard-delete',
        });
        $(document).on('click', '[data-cc-undo]', $.proxy(this, 'deleteCancel') );
        $(document).on('click', '[data-cc-delete-modal]', $.proxy(this, 'deleteAjax') );
    };

    DeleteCreditCard.prototype.deleteAjax = function() {
        $('[data-delete-credit-card]').removeClass('hide');
        if ($('[data-delete-credit-card]').length <= 1)
            $('[data-delete-credit-card]').addClass('hide');
        var self = this;
        $.ajax({
            url: this.$element.attr('href'),
            type: 'GET',
            data: {
                creditcard: this.options.ccId
            },
            statusCode: {
                401: function(data) {
                   var target =  JSON.parse(data.responseText);
                    window.location = target.urlTarget;
                }
            },

        }).success( function(){
            self.$parent.remove();
            self.showNotification('Cartão removido!')
        });
        this.deleteCancel();
    };

    DeleteCreditCard.prototype.deleteCancel = function() {
        $('body').modal('close');
    };
    
    DeleteCreditCard.prototype.showNotification = function(message) {
        this.deleteCancel();
        this.$container = $('.update-notification');
        if ( this.$container.length === 0 ) {
            this.$container = $('<div class="update-notification notification"/>');
            this.$container.append($('<i class="icon-check-sharp"/>'));
            this.$container.append('<p>'+message+'</p>');
            $('<div class="container-message hello col-xs-12"/>').append(this.$container).prependTo('.l-full-content.l-container');
        } else {
            $('.container-message').stop();
            this.$container.html('<i class="icon-check-sharp"/> <p>'+message+'</p>').parent().addClass('hello');
        }
        $('.container-message').delay(5000).queue(function(next) {
            $(this).removeClass('hello');
            next();
        });
    };

    $.fn.creditCard = function(option, item) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.delete-credit-card');
            var options = $.extend({}, DeleteCreditCard.DEFAULTS, $this.data(), typeof option == 'object' && option);

            $this.data('dft.delete-credit-card', (data = new DeleteCreditCard(this, options)));
            if (typeof option == 'string') data[option](item);
        });
    };

    $(document).on('click', '[data-delete-card]', function(event) {
        event.preventDefault();
        $(this).creditCard();
    });

    $(document).on('change', '[data-default-card]', function(event) {
        event.preventDefault();
        var $el = $(event.currentTarget);
        var formCards = $el.parents('.form-cards');
        var parentThis = $(this).closest('tr');
        var data = formCards.serialize();
        formCards.find('.default-message').addClass('hide-message');

        $.ajax({
                url: formCards.attr('action'),
                type: 'POST',
                data: data,
                statusCode: {
                    401: function(data) {
                       var target =  JSON.parse(data.responseText);
                       window.location = target.urlTarget;
                    }
                },

            })
            .done(function() {
                var name = parentThis.find('.name').text().replace('(Padrão)','');
                parentThis.find('.default-message').removeClass('hide-message');
                $(this).creditCard('showNotification', '“'+name+'” agora é seu cartão padrão.');
            })
            .fail(function() {
                $(this).creditCard('showNotification', 'Erro.');
            });
    });

    $(document).on('click', '[data-resend-voucher]', function(event) {
        event.preventDefault();
        $(document).trigger('dft.events-monitoring');
        $.ajax({
                url: $(this).attr('href'),
                statusCode: {
                    401: function(data) {
                        var target =  JSON.parse(data.responseText);
                        window.location = target.urlTarget;
                    }
                },

            })
            .done(function() {
                $(this).creditCard('showNotification', 'Códigos reenviados por e-mail!');
            })
            .fail(function() {
                $('[data-resend-voucher]').addClass('hide');
                $('[data-resend-voucher]').prev('.info').addClass('hide');
                $('[data-resend-voucher]').next().removeClass('hide');
            });
    });

})(jQuery);
