(function($) {

    'use strict';

    var StockAvailable = function(element, options) {
        this.$element = $(element);
        this.$button = this.$element.find('[data-add-to-cart-form-button]');
        this.$soldOutText = this.$element.find('[data-sold-out-text]');
        this.$notifyWhenBack = this.$element.find('[data-notify-when-back]');
        this.options = options;
        this.ajaxActive = false;
        this.$colorCarousel = this.$element.find('.carousel-items');
    };

    StockAvailable.DEFAULTS = {
        url: null,
        async: true
    };

    StockAvailable.prototype.load = function() {
        if (!this.ajaxActive) {
            this.ajaxActive = true;
            $.ajax({
                    url: this.options.url,
                    async: this.options.async,
                    cache: false,
                    global: false
                }).success($.proxy(this, 'loaded'))
                .error($.proxy(this, 'loaded'));
        }
    };

    StockAvailable.prototype.loaded = function(data) {
        var isSoldOut = this.checkIsSoldOut(data.sizes);

        if (isSoldOut) {
            $(document).trigger('dft.product-sold-out');
        }
        
        var sizes = typeof data.sizes == 'undefined' ? [] : data.sizes;
        var sku = this.$element.find('input[name=p]').val();

        this.$element.find('.add-to-cart-form').addToCartForm('sizes', sizes);
        this.$element.toggleClass('is-sold-out', isSoldOut);
        this.$button.attr('disabled', isSoldOut);

        if (this.$button.attr('disabled')) {
            this.$button.addClass('hide');
            this.$soldOutText.removeClass('hide');
            this.$notifyWhenBack.removeClass('hide');
        }

        if (window.location.pathname.search('/wishlist/') > 0 && isSoldOut) {
            this.$button.removeAttr('data-add-to-cart-form-button')
                .addClass('sold-out').removeClass('primary hide')
                .attr('type', 'button')
                .attr('data-remote', '/catalog/sizeReminder?sku=' + sku)
                .attr('data-toggle', 'modal')
                .attr('data-class-name', 'modal-size-reminder')
                .text('Avise-me')
                .removeAttr('disabled')
                .before('<p class="myaccount-wishlist-sold-out-text">Produto Esgotado</p>');

            this.$element.find('.add-to-cart-select').parent().addClass('hide');
            this.$element.find('.add-to-cart-arrow').addClass('hide');
        }

        this.$element.find('[data-field=price]').text(data.price);
        this.$element.find('[data-field=installments-count]').text(data.installments && data.installments.count);
        this.$element.find('[data-field=installments-value]').text(data.installments && data.installments.value);
        this.specialPriceAvailable(data.specialPrice);

        var installmentCampaign = this.checkIfHaveInstallmentCampaign(data.campaigns);
        this.setPercentageInstallmentCampaign(installmentCampaign);

        $('.appears-cash-discount-info, .catalog-detail-price-cash-text').on('click', function(){
            $('.info-cash-discount-popup-mobile, .arrow-cash-discount-icon-mobile').show();
        });

        $('.info-cash-discount-popup-close').on('click', function(){
            $('.info-cash-discount-popup-mobile, .arrow-cash-discount-icon-mobile').hide();
        });

        if (isSoldOut) {
            this.$button.html(this.$button.data('disabled-title'));
            this.$element.find('.netotiate-row').remove();
        }
        if (typeof data.colors !== 'undefined') {
            this.colorsCarousel(data.colors);
        }
        
        this.$element.addClass('is-loaded');
        this.$element.find('.loading-dialog').addClass('is-loaded');
        this.$element.trigger('loaded');
        this.ajaxActive = false;
    };

    StockAvailable.prototype.checkIfHaveInstallmentCampaign = function (campaigns) {
        return (typeof campaigns == "undefined" || typeof campaigns.installments == "undefined"
                || campaigns.installments.length == 0)
            ? false
            : campaigns.installments;
    };
    
    StockAvailable.prototype.setPercentageInstallmentCampaign = function(installments) {
        if (installments) {
            var element = this.$element.find('[data-field=percentage]');
            if (element.length > 0) {
                element.text(installments.discountPercentage);
            }
        } else {
            this.$element.find('[data-text=percentage]').remove();
        }
    };

    StockAvailable.prototype.sizeGuide = function() {
        var hasSizeGuide = this.$element.find('[data-size-guide]').children().hasClass('stock-available-sizeguide-title');
        if (!hasSizeGuide) {
            this.$element.find('[data-sizes]').removeClass('col-xs-9').addClass('col-xs-12');
        }
    };

    StockAvailable.prototype.colorsCarousel = function(colors) {
        if (colors.length > 0) {
            var colorElementBase = this.$colorCarousel.find('[data-color]');

            if (colors.length > 4) {
                this.$element.find('[data-color-navigation]').toggle();
                this.$element.find('[catalog-detail-colors-list]').removeClass('col-xs-8').addClass('col-xs-4 col-xs-push-2');
                colorElementBase.removeClass('col-xs-3').addClass('col-xs-6');
            }

            var that = this;

            $.each(colors, function(index, color) {
                var colorElement = colorElementBase.clone();
                colorElement.find('[data-color-link]').attr('href', color.link);
                colorElement.find('[data-color-link]').attr('title', color.name);
                colorElement.find('[data-color-img]').attr('src', color.image_url);
                colorElement.appendTo(that.$colorCarousel);
            });

            colorElementBase.remove();
            this.$element.find('[data-color-carousel]').toggle();
        }
    };

    StockAvailable.prototype.specialPriceAvailable = function(specialPrice) {
        if (specialPrice) {
            this.$element.find('[data-field=specialPrice]').text(specialPrice);
        } else {
            this.$element.find('[data-field=specialPrice]').remove();
        }
    };

    StockAvailable.prototype.checkIsSoldOut = function(sizes) {
        var count = 0;

        for (var i in sizes) {
            count += parseInt(sizes[i].stock, 10);
        }

        return count === 0;
    };

    $.fn.stockAvailable = function(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.stockavailable');
            var options = $.extend({}, StockAvailable.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.stockavailable', (data = new StockAvailable(this, options)));
            if (typeof option == 'string') data[option]();
        });
    };

    $(document).on('change', '.add-to-cart-select', function() {
        var dataValues = $(this).find('option:selected').data();

        if (dataValues.sizeReminder === 'wishlist') {
            $('body').modal({
                'remote': dataValues.remote
            });
        }
    });

})(jQuery);