(function($) {

    'use strict';

    var SwipeDft = function(element, options) {
        this.$element = $(element);
        this.options = options;
        this.init();
    };

    SwipeDft.DEFAULTS = {
        el: {
            slider:   $('#gallery'),
            holder:   $('.gallery-preview'),
            imgSlide: $('.gallery-preview-img'),
        },
        slideWidth:   $('#gallery').width(),
        touchstartx:  undefined,
        touchstarty:  undefined,
        touchmovex:   undefined,
        touchmovey:   undefined,
        movex:        undefined,
        index:        0,
        longTouch:    undefined,
        mysetTimeout: undefined
    };

    SwipeDft.prototype.init = function() {
        this.bindUIEvents();
        this.calcWidths();
    };

    SwipeDft.prototype.calcWidths = function() {
        var numImages = $('.gallery-preview-img').length;
        var widthWrapper = 100 / numImages;
        var widthHolder = 100 * numImages;
        $('.slide-wrapper').css('width', widthWrapper + '%');
        this.options.el.holder.css('width', widthHolder + '%');
        $('.gallery-preview').removeClass('hide');
        $('.gallery-pager-item a').first().addClass('selected').trigger('click');
        $('.slide-wrapper').first().addClass('active');
        this.options.slideWidth = $('#gallery').width();
    };

    SwipeDft.prototype.bindUIEvents = function() {
        var self = this;
        this.options.el.holder.on('touchstart', function(event) {
            if (!$(this).hasClass('is-zoom-active'))
                self.start(event);
        });

        this.options.el.holder.on('touchmove', function(event) {
            if (!$(this).hasClass('is-zoom-active'))
                self.move(event);
        });

        this.options.el.holder.on('touchend', function() {
            if (!$(this).hasClass('is-zoom-active'))
                self.end();
        });
        $(document).on('click', '.gallery-pager-item', function(event) {
            event.preventDefault();
            self.bullets($(this).index() - 1);
        });

    };

    SwipeDft.prototype.start = function(event) {
        var self = this;
        self.options.mysetTimeout = setTimeout(function() {
            self.options.longTouch = true;
        }, 60);
        self.options.longTouch = false;
        this.options.touchstartx = event.originalEvent.touches[0].pageX;
        this.options.touchstarty = event.originalEvent.touches[0].pageY;
        $('.animate').removeClass('animate');

        $('[data-lazyload]').lazyload();
    };

    SwipeDft.prototype.move = function(event) {
        this.options.touchmovex = event.originalEvent.touches[0].pageX;
        this.options.touchmovey = event.originalEvent.touches[0].pageY;
        this.options.movex = this.options.index * this.options.slideWidth + (this.options.touchstartx - this.options.touchmovex);

        var numImages = $('.gallery-preview-img').length;
        var totalWidth = this.options.el.imgSlide.width() * numImages;
        var mx = this.options.touchstartx - this.options.touchmovex;
        if ( this.options.movex < ( totalWidth - this.options.el.imgSlide.width() ) ) {
            this.options.el.holder.css('transform', 'translate3d(-' + this.options.movex + 'px,0,0)');
            if ( mx > 20 || mx < -20 )
                event.preventDefault();
        }
        event.stopPropagation();
    };

    SwipeDft.prototype.end = function() {
        clearTimeout(this.options.mysetTimeout);
        var numImages = $('.gallery-preview-img').length;
        var mx = this.options.touchstartx - this.options.touchmovex;
        var my = this.options.touchstarty - this.options.touchmovey;
        if( mx < 0 ) mx = mx *-1;
        if( my < 0 ) my = my *-1;
        if ( this.options.movex >= this.options.slideWidth / 10 /*&& this.options.longTouch === true*/ && ( mx > my ) ) {
            if (this.options.movex > this.options.index * this.options.slideWidth && this.options.index <= (numImages - 2)) {
                this.options.index++;
            } else if (this.options.movex < this.options.index * this.options.slideWidth && this.options.index > 0) {
                this.options.index--;
            }
        }

        this.options.el.holder.addClass('animate').css({'transform': 'translate3d(-' + this.options.index * this.options.slideWidth + 'px,0,0)'});

        this.options.movex = 0;
        $('.gallery-pager-item').find('a').removeClass('selected');
        $($('.gallery-pager-item').get(this.options.index)).find('a').addClass('selected');
        $('.slide-wrapper.active').removeClass('active');
        $($('.slide-wrapper').get(this.options.index)).addClass('active');
        if ($('.slide-wrapper.active').hasClass('catwalk'))
            $('.gallery-zoom-button').addClass('hide');
        else
            $('.gallery-zoom-button').removeClass('hide');
    };

    SwipeDft.prototype.bullets = function(index) {

        if ($('.gallery-preview').hasClass('is-zoom-active'))
            $('.gallery-zoom-button').trigger('click');

        this.options.el.holder.addClass('animate').css({'transform': 'translate3d(-' + index * this.options.slideWidth + 'px,0,0)'});
        this.options.el.imgSlide.addClass('animate').css({'transform': 'translate3d(-' + 100 - index * 50 + 'px,0,0)'});

        $('.gallery-pager-item').find('a').removeClass('selected');
        $($('.gallery-pager-item').get(index)).find('a').addClass('selected');
        $('.slide-wrapper.active').removeClass('active');
        $($('.slide-wrapper').get(index)).addClass('active');
        if ($('.slide-wrapper.active').hasClass('catwalk'))
            $('.gallery-zoom-button').addClass('hide');
        else
            $('.gallery-zoom-button').removeClass('hide');
        this.options.index = index;

    };

    $.fn.swipeDft = function(option, args) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.swipe');
            var options = $.extend({}, SwipeDft.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.swipe', (data = new SwipeDft(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    var Gallery = function(element, options) {
        this.$element = $(element);
        this.options = options;
        this.bind();
    };

    Gallery.DEFAULTS = {
        zoom: true,
        posIni: true,
        hasCatwalk: false,
        isZooming: false
    };

    Gallery.prototype.bind = function() {
        this.$element.find('.gallery-preview')
            .on('movestart', function(e) {
                if ((e.distX > e.distY && e.distX < -e.distY) ||
                    (e.distX < e.distY && e.distX > -e.distY)) {
                    e.preventDefault();
                }
            });
    };

    Gallery.prototype.setPosIniTrue = function() {
        this.options.posIni = true;
    };

    Gallery.prototype.turnZoomOff = function() {
        this.options.isZooming = false;
    };

    Gallery.prototype.zoom = function(el) {
        if (this.options.zoom === false) {
            return;
        }
        $(el).find('.gallery-preview-img').addClass('hide');
        $(el).css({
            'background-image': 'url(' + $(el).attr('data-img-zoom').replace(/'/g, '') + ')',
            'background-position': '50% 0'
        });

    };

    Gallery.prototype.navZoom = function(event, el) {
        var limitX = $(el).width();
        var limitY = $(el).height();
        var offset = $(el).offset();
        var magnifierLeft = event.pageX - offset.left;
        var magnifierTop = event.pageY - offset.top;


        if (magnifierLeft < 0) magnifierLeft = 0;
        if (magnifierTop < 0) magnifierTop = 0;
        if (magnifierLeft > limitX) magnifierLeft = limitX;
        if (magnifierTop > limitY) magnifierTop = limitY;

        var bgLeft = 100 - ((magnifierLeft * 100) / limitX);
        var bgTop = 100 - ((magnifierTop * 100) / limitY);

        if (this.options.posIni === true) {
            bgLeft = 50;
            bgTop = 0;
            this.options.posIni = false;
        }
        $(el).css('background-position', bgLeft + '% ' + bgTop + '%');
    };

    Gallery.prototype.zoomEnd = function() {
        this.$element.find('.gallery-preview').css({
            'height': 'auto',
            'background-image': 'none'
        });
        this.$element.find('.gallery-preview-img').removeClass('hide');
    };

    Gallery.prototype.loadCatwalk = function() {
        Catwalk.load_catwalk(this, 'loadedCatwalk');
    };

    Gallery.prototype.loadedCatwalk = function(data, _this) {
        if (data != '') {
            this.options.hasCatwalk = true;
            this.$element.gallery('zoomEnd');
            this.$element.gallery('setPosIniTrue');

            _this.createCatwalkElements(_this.$element);
            $('.slide-wrapper').first().find('.catwalk-play-big-icon').after(data);
            $('#catwalk-image-finish').attr('src', $('.slide-wrapper').last().find('.gallery-preview-img').attr('src'));
            $('#gallery').swipeDft('calcWidths');
            _this.$element.on('click', '.gallery-pager-link', function() {
                _this.toggleCatwalkIcon($(this), _this.$element);
            });
        }
    };

    Gallery.prototype.createCatwalkElements = function(galleryEl) {
        var lastImg = $('.slide-wrapper').last().clone();
        lastImg.addClass('catwalk active').prependTo('.gallery-preview');
        galleryEl.find('.gallery-pager').addClass('has-catwalk');

        var lastItem = galleryEl.find('.gallery-pager-item').last().clone();
        galleryEl.find('.gallery-pager').addClass('has-catwalk');
        galleryEl.find('.icon-zoom_in').after(lastItem);
        galleryEl.find('.gallery-pager-link').first().addClass('catwalk selected');
        $('.slide-wrapper').first().find('.slide').append('<a class="catwalk-play-big-icon icon-icon_circle_play catwalk-actions-link-play show"></a>');
        $('.gallery-pager-link').each(function(indice, item) {
            $(item).attr('data-slide-index', indice + 1);
        });
    };

    Gallery.prototype.swipeBlock = function(event) {
        var el = event.currentTarget;
        if (this.options.isZooming) {
            this.navZoom(event, el);
        }
    };

    Gallery.prototype.zoomOutZoomIn = function(el) {
        $('.gallery-preview').addClass('is-zoom-active');
        $(el).addClass('in-zoom');

        var galleryHeight = $('.gallery-preview').height();
        if (this.options.isZooming) {
            $('html, body').css({
                'overflow-y': 'auto',
                'height': 'auto',
                '-webkit-overflow-scrolling': 'auto'
            });
            this.zoomEnd(el);
            this.setPosIniTrue(el);

            $('.gallery-zoom-button').removeClass('icon-zoom_out').addClass('icon-zoom_in');
            $('.gallery-preview').removeClass('is-zoom-active');
            $(el).removeClass('in-zoom');
            this.options.isZooming = false;
        } else {
            this.options.isZooming = true;
            $('.gallery-zoom-button').removeClass('icon-zoom_in').addClass('icon-zoom_out');
            $('.gallery-preview').css('height', galleryHeight);
            $('html, body').css({
                'overflow-y': 'hidden',
                'height': '100%',
                '-webkit-overflow-scrolling': 'touch'
            });
            if (!$(this).parent().hasClass('catwalk')) {
                this.zoom(el);
            }
            this.zoom(el);

        }
    };

    Gallery.prototype.hideCatwalk = function() {
        if (!$(this).hasClass('catwalk')) {
            $('.product-img-box-video, .catwalk-play-big-icon').removeClass('show');
            $('.gallery-preview').removeClass('is-hidden');

            if (this.options.hasCatwalk) {
                Catwalk.video.pause();
            }
        }
    };

    Gallery.prototype.toggleCatwalkIcon = function(_this, galleryEl) {
        var $this = _this;
        if ($this.parent().is(':first-child')) {
            galleryEl.addClass('catwalk');
        } else {
            galleryEl.removeClass('catwalk');
        }
    };

    Gallery.prototype.loadZoomImage = function() {
        var $img = this.$element.find('.gallery-pager-link');
        $img.attr('src', $img.data('img-zoom'));
        if (!$(this).hasClass('catwalk')) {
            $('.gallery .gallery-pager-link').trigger('click');
            $('.catwalk-play-big-icon').addClass('show');
        }
        $($('.gallery-thumb').get(0)).trigger('click');
    };

    Gallery.prototype.zoomOpen = function(el) {
        $('html, body').addClass('is-modal-open');
        var imgActive = $(el).attr('data-img-zoom');
        $('.slider-zoom').removeClass('hide').find('.imgActive').attr('src', imgActive);
        $("meta[name=viewport]").attr('content', 'width=device-width, initial-scale=1');
        $('.zoom-close').show();
    }

    Gallery.prototype.zoomClose = function(el) {
        $(el).hide();
        $('.slider-zoom').addClass('hide');
        $("meta[name=viewport]").attr('content', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no');
        $('html, body').removeClass('is-modal-open');
        $('.zoom-close').show();
    }

    Gallery.prototype.zoomReset = function(el) {
        $("meta[name=viewport]").attr('content', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no');
        $('.zoom-close').show(function() {
            $("meta[name=viewport]").attr('content', 'width=device-width, initial-scale=1');
        });
    }

    $.fn.gallery = function(option, args) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.gallery');
            var options = $.extend({}, Gallery.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.gallery', (data = new Gallery(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('touchmove', '.in-zoom', function(event) {
        $('#gallery').gallery('swipeBlock', event);
    });

    $(document).on('click touchend', '.slide-wrapper', function() {
        $('.gallery:not(.catwalk) .gallery-preview').trigger('doubletap');
    });

    $(document).on('click', '.catwalk-actions-link-play', function() {
        $('.video-controls').removeClass('small-catwalk');
        $(this).prev('.gallery-preview-img').addClass('hidden');
        $('.catwalk-play-big-icon').prev('.gallery-preview-img');
        $('.product-img-box-video').removeClass('is-hidden').addClass('show');
    });

    $(document).on('click', '.gallery-zoom-button', function() {
        $('.slide-wrapper.active').trigger('click');
    });

    $(document).ready(function() {
        $('#gallery').swipeDft();
        $('body').prepend('<div class="slider-zoom hide"><a href="#" class="zoom-close icon-cross"></a><i class="icon-loading loading-indicator"></i><img src="" alt="" class="imgActive"></div>');
    });

    $(document).on('click', '.slide-wrapper:not(.catwalk)', function() {
        $('#gallery').gallery('zoomOpen', this);
    });

    $(document).on('click touchend', '.zoom-close', function(event) {
        $('#gallery').gallery('zoomClose', this);
        event.preventDefault();
    });

    $(document).on('doubletap', '.slider-zoom', function() {
        $('.zoom-close').trigger('click');
    });

    $(document).on('scroll', function(e) {
        if ( $('.slider-zoom').is(':visible') && window.innerWidth >= document.documentElement.clientWidth) {
            $('.zoom-close').show();
        } else {
            $('.zoom-close').hide();
        }
    });

})(jQuery);
