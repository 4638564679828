(function ($) {

    'use strict';

    var DeliveryTimeForm = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.$form = this.$element.closest('form');
        this.$postcode = this.$form.find('[data-delivery-time-postcode]');
    };

    DeliveryTimeForm.DEFAULTS = {};

    DeliveryTimeForm.prototype.validate = function() {
        var isInvalid = this.$postcode.val().length == 0;

        this.$postcode.toggleClass('error', isInvalid);
        this.load();
    };

    DeliveryTimeForm.prototype.load = function(e) {
        var data = {
            'sku' : this.getSelectedSku(),
            'domain' : this.$form.find('[data-delivery-time-domain]').val(),
            'b2014' : 1,
            'postcode' : this.$postcode.val()
        };

        var that = this;
        $.ajax({
            'url' : this.$form.attr('action'),
            'type' : 'GET',
            'data' : data,
            'success' : function(response) {
                that.loaded(response);
            },
            'error' : function(response) {
                var errorMessage = '<h2 class="product-delivery-time-title error">' + response.responseText + '</h2>';

                that.loaded(errorMessage);
            }
        });
    };

    DeliveryTimeForm.prototype.getSelectedSku = function() {
        var sku = this.getSkuFromSelectedSize();

        if (sku == '') {
            sku = this.getFirstSkuThatHasStock();
        }

        return sku;
    };

    DeliveryTimeForm.prototype.getSkuFromSelectedSize = function() {
        return $('.add-to-cart-select').val();
    };

    DeliveryTimeForm.prototype.getFirstSkuThatHasStock = function() {
        var sku = null;

        $('[data-sizes-item]').each(function(key, option) {
            var value = $(option).val();
            if (value) {
                sku = value;
                return false;
            }
        });

        return sku;
    };

    DeliveryTimeForm.prototype.loaded = function (response) {
        $('[data-delivery-time-form-response]').html(response);
    };

    $.fn.deliveryTimeForm = function (option, args) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('dft.delivery-time-form');
            var options = $.extend({}, DeliveryTimeForm.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.delivery-time-form', (data = new DeliveryTimeForm(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('click', '[data-delivery-time-submit]', function(e) {
        e.preventDefault();
        $(e.currentTarget).deliveryTimeForm('validate');
    });

    $(document).on('click', '.product-delivery-time-selller-info', function() {
        $(this).next('.accordion-item-content').slideToggle('slow');
    });

})(jQuery);
