(function ($) {

    'use strict';


    var EVENT_OBJECT_GA3 = {
        event: 'select_content_ga3',
        category: 'menu',
        action: 'click - text',
        label: ''
    }

    var EVENT_OBJECT_GA4 = {
        event: 'select_content_ga4',
        content_type: 'click - menu',
        item_id: '',
        element: 'text'
    }

    var category1 = '';
    var category2 = '';

    var prepareEvent = function () {
        category1 = category1.innerText.toLowerCase();
        category2 = category2.innerText.toLowerCase();

        var categorys = category1 + ' - ' + category2;
        EVENT_OBJECT_GA3.label = categorys;
        EVENT_OBJECT_GA4.item_id = categorys;
    }

    var prepareOneLevelMenuEvent = function (menu) {

        EVENT_OBJECT_GA3.label = menu;
        EVENT_OBJECT_GA4.item_id = menu;
    }

    var sendEvent = function () {
        $(document).trigger(
            'dft.menu-tracking',
            EVENT_OBJECT_GA3
        );
        $(document).trigger(
            'dft.menu-tracking',
            EVENT_OBJECT_GA4
        );
    }

    var listenMenu = function () {
        $(document).on('click', '[data-item^=item] a', function (e) {
            category1 = e.target.closest('[data-item^=item]').querySelector('label');
            category2 = e.target;

            prepareEvent();
            sendEvent();
        });
    }

    var oneLevelMenu = function () {
        $(document).on('click', '.menu-stacked-item > a.menu-stacked-link', function (e) {
            if (category1 === '' && category2 === '') {
                var menu = e.currentTarget.childNodes[0].data.trim();
                prepareOneLevelMenuEvent(menu);
                sendEvent();
            }
        });
    }

    return {
        init: function () {
            listenMenu();
            oneLevelMenu();
        }
    }

})(jQuery).init();
