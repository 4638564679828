(function ($) {
    'use strict';

    $(document).ready(function() {
        $('.submenu-control').click(function(e) {
            const self = $(this);
            const ul = self.siblings('ul');

            if (ul.is(':visible')) {
                const icon = self.children('.icon-chevron-down')
                icon.animate({
                    borderSpacing: -90
                }, {
                    duration: 400,
                    step: function (now, fx) {
                        icon.css('transform', 'rotate(' + now + 'deg)');
                    },
                    complete: function() {
                        icon.css('transform', 'rotate(0deg)');
                    }
                })
                ul.slideUp(400, function () {
                    self.parent().parent().removeClass('expanded');
                })
            } else {
                const icon = self.children('.icon-chevron-right');
                icon.animate({
                    borderSpacing: 90
                }, {
                    duration: 400,
                    step: function (now, fx) {
                        icon.css('transform', 'rotate(' + now + 'deg)');
                    },
                    complete: function() {
                        icon.css('transform', 'rotate(0deg)');
                    }
                })
                ul.slideDown(400, function() {
                    self.parent().parent().addClass('expanded');
                })
            }
        })
    })
})(jQuery);