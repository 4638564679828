window.Catwalk = function(ns) {
    ns.options = {
        'playback_class': 'catwalk-play',
        'mute_class': 'catwalk-unmute',
        'video_object': 'catwalk-video-stream',
        'audio_object': 'catwalk-soundtrack',
        'catwalk_actions_link': 'catwalk-actions-link',
        'video_count': 0,
        'repeat_count': 2,
        'muted': true,
        'controls': false
    };

    ns.repeat_count = ns.options.repeat_count;
    ns.video_count = ns.options.video_count;
    ns.video = $('.'+ns.options.video_object)[0];
    ns.audio = $('.'+ns.options.audio_object)[0];
    ns.playpause_action = $('.'+ns.options.playback_class)[0];
    ns.mute_action = $('.'+ns.options.mute_class)[0];
    ns.catwalk_controls = $('.'+"catwalk-controls")[0];

    ns.load_catwalk = function(context, callback) {
        var productSku = $('.add-to-cart-form [name=p]').val();
        $.ajax({
            type: 'get',
            url: '/catwalkBox?b2014=1',
            async: false,
            data: {sku:productSku, version:document.createElement('canvas').getContext != undefined && (!!document.createElement('video').canPlayType('video/mp4; codecs=avc1.42E01E,mp4a.40.2')) ? 'html5':'html5'},
            success: function(data){
                context[callback](data, context);
                $(document).trigger('dft.catwalk.loaded');
            }
        });
    };

    ns.play = function() {
        if (ns.video.getAttribute('src') == "") {
            return false;
        }
        if (ns.video.paused) {
            ns.video.play();
            ns.audio.play();
            ns.playpause_action.className = 'catwalk-controls-button catwalk-pause';
            ns.playpause_action.children[0].className = 'i-catwalk-pause';
        } else {
            ns.playpause_action.className = 'catwalk-controls-button catwalk-play';
            ns.playpause_action.children[0].className = 'i-catwalk-play';
            ns.video.pause();
            ns.audio.pause();
        }
    };

    ns.mute = function() {
        ns.video.muted = true;
        if (ns.audio.muted) {
            ns.audio.muted = false;
            ns.mute_action.className = 'catwalk-controls-button catwalk-unmute';
            ns.mute_action.children[0].className = 'i-catwalk-unmute';
        } else {
            ns.mute_action.className = 'catwalk-controls-button catwalk-mute';
            ns.mute_action.children[0].className = 'i-catwalk-mute';
            ns.audio.muted = true;
        }
    };

    ns.setTime = function() {
        try {
            if (tValue == 0) {
                ns.video.currentTime = tValue;
                ns.audio.currentTime = 0;
            } else {
                ns.video.currentTime += tValue;
                ns.audio.currentTime = 0;
            }

        } catch (err) {
            ns.errMessage("Video content might not be loaded");
        }
    };

    ns.updateProgress = function() {
        var progress = $('.'+'catwalk-controls-progressbar-progress')[0];
        var progress2 = $('.'+'catwalk-controls-progressbar-slider')[0];
        var progressbar = $('.'+'catwalk-controls-progressbar')[0];
        var value = 0;
        if (ns.video.currentTime > 0) {
            value = Math.floor((100 / ns.video.duration) * ns.video.currentTime);
        }
        var value2 = (progress2.value - progress2.min) / (progress2.max - progress2.min);
        progress2.value = ns.video.currentTime;
        progress.setAttribute('style', ns.getbackgroundStyle(parseInt(value)));
    };

    ns.getbackgroundStyle = function(pos) {
        var style = '';
        style += ' background: #a4a4a4;';
        style += ' background: -moz-linear-gradient(left, #a4a4a4 0%, #a4a4a4 ' + pos + '%, #545454 ' + pos + '%, #545454 100%, #545454 100%);';
        style += ' background: -webkit-gradient(linear, left top, right top, color-stop(0%,#a4a4a4), color-stop(' + pos + '%,#545454), color-stop(' + pos + '%,#545454), color-stop(100%,#a4a4a4), color-stop(100%,#545454)); ';
        style += ' background: -webkit-linear-gradient(left, #a4a4a4 0%,#a4a4a4 ' + pos + '%,#545454 ' + pos + '%,#545454 100%,#545454 100%);';
        style += ' background: -o-linear-gradient(left, #a4a4a4 0%,#a4a4a4 ' + pos + '%,#545454 ' + pos + '%,#545454 100%,#545454 100%);';
        style += ' background: -ms-linear-gradient(left, #a4a4a4 0%,#a4a4a4 ' + pos + '%,#545454 ' + pos + '%,#545454 100%,#545454 100%); ';
        style += ' background: linear-gradient(to right, #a4a4a4 0%,#a4a4a4 ' + pos + '%,#545454 ' + pos + '%,#545454 100%,#545454 100%); ';
        style += ' filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=\'#a4a4a4\', endColorstr=\'#545454\',GradientType=1 );';
        return style;
    };

    ns.setVol = function() {
        var vol = ns.video.volume;
        vol += value;
        if (vol >= 0 && vol <= 1) {
            ns.video.volume = vol;
            ns.audio.volume = 0;
        } else {
            ns.video.volume = (vol < 0) ? 0 : 1;
            ns.audio.volume = (vol < 0) ? 0 : 1;
        }
    };

    ns.errMessage = function(msg) {
        console.log(msg);
    };

    ns.start = function() {
        var catwalk_credits = $('.'+"catwalk-credits")[0];
        catwalk_credits.className = catwalk_credits.className.replace(/\bshow\b/gi, '');
        catwalk_credits.className += ' hidden';

        var catwalk_credits = $('.'+"catwalk-credits")[0];
        catwalk_credits.className = catwalk_credits.className.replace(/\bshow\b/gi, '');
        catwalk_credits.className += ' hidden';

        var video_controls = $('.'+"video-controls")[0];
        video_controls.className = video_controls.className.replace(/\bhidden\b/gi, '');
        video_controls.className += ' show';

        var action_video_playing = $('.'+'catwalk-actions-link-playing')[0];
        action_video_playing.className = action_video_playing.className.replace(/\bshow\b/gi, '');
        action_video_playing.className += ' hidden';

        var action_video_play = $('.'+'catwalk-actions-link-play')[0];
        action_video_play.className = action_video_play.className.replace(/\bhidden\b/gi, '');
        action_video_play.className += ' show';

        ns.video.className = ns.video.className.replace(/\bshow\b/gi, '');
        ns.video.className += ' hidden';


        ns.video.currentTime = 0;
        ns.audio.currentTime = 0;
        ns.video.muted = true;
        ns.audio.muted = false;

    };

    ns.end = function() {
        ns.catwalk_controls.className = ns.catwalk_controls.className.replace(/\bshow\b/gi, '');
        ns.catwalk_controls.className += ' hidden';

        var catwalk_credits = $('.'+"catwalk-credits")[0];
        catwalk_credits.className = catwalk_credits.className.replace(/\bhidden\b/gi, '');
        catwalk_credits.className += ' show';

        var video_controls = $('.'+"video-controls")[0];
        video_controls.className = video_controls.className.replace(/\bshow\b/gi, '');
        video_controls.className += ' hidden';

        ns.video_count = 0;
        ns.video.currentTime = 0;
        ns.audio.currentTime = 0;
        ns.video.muted = true;
        ns.audio.muted = false;
        ns.audio.removeAttribute('loop');
        ns.video.removeAttribute('controls');
        $('.'+'catwalk-controls-progressbar-slider')[0].setAttribute('disabled', 'disabled');
        ns.audio.pause();
        ns.video.pause();

    };

    ns.begin_walk = function() {
        ns.video.className = ns.video.className.replace(/\bhidden\b/gi, '');
        ns.video.className += ' show';

        ns.catwalk_controls.className = ns.catwalk_controls.className.replace(/\bhidden\b/gi, '');
        ns.catwalk_controls.className += ' show';


        $('.'+'catwalk-controls-progressbar-slider')[0].removeAttribute('disabled');

        ns.catwalk_controls.className = ns.catwalk_controls.className.replace(/\bhidden\b/gi, '');
        ns.catwalk_controls.className += ' show';

        var randomnumber = 0;
        var sounds = ns.video.getAttribute('audio');
        var author = ns.video.getAttribute('author');
        var music = ns.video.getAttribute('music');

        if ((typeof sounds != 'undefined') && sounds.length > 0) {
            var soundArr = sounds.split(',');
            if ((typeof soundArr != 'undefined') && soundArr.length > 0) {
                randomnumber = ((Math.random() * (soundArr.length) | 0) + 1) - 1;
                ns.audio.setAttribute('src', soundArr[randomnumber]);
                ns.audio.setAttribute('autoplay', 'autoplay');
                ns.audio.setAttribute('loop', 'loop');
                ns.audio.play();
            }
        }

        if ((typeof author != 'undefined' && author != null) && author.length > 0) {
            var authorArr = author.split(',');
            var musicArr = music.split(',');

            if ((typeof authorArr != 'undefined') && authorArr.length > 0 && (typeof musicArr != 'undefined') && musicArr.length > 0) {
                $('.'+'catwalk-credits-author')[0].innerHTML = authorArr[randomnumber];
                $('.'+'catwalk-credits-music')[0].innerHTML = musicArr[randomnumber];
            }
        }

        if (!ns.video.paused) {
            ns.video.pause();
        } else {
            ns.video.play();
        }
    };

    ns.init = function() {

        if(!ns.video){
            ns.repeat_count = ns.options.repeat_count;
            ns.video_count = ns.options.video_count;
            ns.video = $('.'+ns.options.video_object)[0];
            ns.audio = $('.'+ns.options.audio_object)[0];
            ns.playpause_action = $('.'+ns.options.playback_class)[0];
            ns.mute_action = $('.'+ns.options.mute_class)[0];
            ns.catwalk_controls = $('.'+"catwalk-controls")[0];
        }

        if (typeof(ns.video) != 'undefined') {
            if (typeof(ns.video.canPlayType) != undefined) {

                $('.'+'catwalk-replay')[0].addEventListener("click", function() {
                    ns.start();
                    ns.begin_walk();
                }, true);

                $('.'+'catwalk-actions-link-finished')[1].addEventListener("click", function() {
                    ns.start();
                }, true);

                $('.'+'catwalk-actions-link-play')[0].addEventListener("click", function() {
                    ns.start();
                    ns.begin_walk();
                }, true);

                $('.'+'catwalk-actions-link-playing')[0].addEventListener("click", function() {
                    ns.end();
                    ns.start();
                }, true);

                ns.playpause_action.addEventListener("click", ns.play, false);
                ns.mute_action.addEventListener("click", ns.mute, false);
                ns.video.addEventListener("error", function(err) {
                    ns.errMessage(err);
                }, true);
                ns.audio.addEventListener("error", function(err) {
                    ns.errMessage(err);
                }, true);

                ns.video.addEventListener("canplay", function() {
                    $('.'+"catwalk-controls")[0].style.display = "block";
                }, false);

                ns.video.addEventListener("timeupdate", function() {
                    var vTime = ns.video.currentTime;
                    $('.'+'catwalk-controls-progressbar-slider')[0].setAttribute('max', ns.video.duration);
                    $('.'+'catwalk-controls-progressbar-slider')[0].setAttribute('val', ns.video.currentTime);
                }, false);

                ns.video.addEventListener("pause", function() {
                    ns.playpause_action.className = 'catwalk-controls-button catwalk-play';
                    ns.playpause_action.children[0].className = 'i-catwalk-play';
                    ns.audio.pause();
                }, false);

                ns.video.addEventListener("playing", function() {
                    ns.audio.play();
                    ns.playpause_action.className = 'catwalk-controls-button catwalk-pause';
                    ns.playpause_action.children[0].className = 'i-catwalk-pause';
                }, false);

                ns.video.addEventListener("volumechange", ns.mute, false);

                ns.video.addEventListener("timeupdate", ns.updateProgress, false);
                ns.video.addEventListener("loadstart", function() {
                    $('.'+'catwalk-controls-progressbar-slider')[0].setAttribute('max', ns.video.duration);
                }, false);
                ns.video.addEventListener("loadeddata", function() {
                    $('.'+'catwalk-controls-progressbar-slider')[0].setAttribute('max', ns.video.duration);
                }, false);

                ns.video.addEventListener("ended", function() {
                    ns.video_count++;

                    if (parseInt(ns.video_count) < parseInt(ns.repeat_count)) {
                        ns.video.play();
                        ns.audio.play();
                    } else {
                        ns.video.currentTime = 0;
                        ns.end();
                    }
                }, false);

                ns.video.addEventListener("progress", function() {
                    $('.'+'catwalk-controls-progressbar-slider')[0].setAttribute('max', ns.video.duration);
                    $('.'+'catwalk-controls-progressbar-slider')[0].removeAttribute('disabled');
                }, false);

                ns.video.addEventListener("durationchange", function() {
                    $('.'+'catwalk-controls-progressbar-slider')[0].setAttribute('max', ns.video.duration);
                    $('.'+'catwalk-controls-progressbar-slider')[0].removeAttribute('disabled');
                }, false);

                ns.video.addEventListener("canplaythrough", function() {}, false);

                $('.'+'catwalk-controls-progressbar-slider')[0].addEventListener("input", function() {
                    ns.video.currentTime = $('.'+'catwalk-controls-progressbar-slider')[0].value;
                    ns.audio.currentTime = $('.'+'catwalk-controls-progressbar-slider')[0].value;
                }, false);
                $('.'+'catwalk-actions-link-play')[0].className = $('.'+'catwalk-actions-link-play')[0].className.replace(/\bhidden\b/gi, '');

                $('.'+'catwalk-actions-link-finished')[0].className = $('.'+'catwalk-actions-link-finished')[0].className.replace(/\bshow\b/gi, '');
                $('.'+'catwalk-actions-link-finished')[0].className += ' hidden';

                $('.'+'catwalk-actions-link-play')[0].className = $('.'+'catwalk-actions-link-play')[0].className.replace(/\bhidden\b/gi, '');

                ns.video.removeAttribute('controls');

                ns.video.className = ns.video.className.replace(/\bshow\b/gi, '');
                ns.video.className += ' hidden';

                ns.catwalk_controls.className = ns.catwalk_controls.className.replace(/\bshow\b/gi, '');
                ns.catwalk_controls.className += ' hidden';

            }
        }
    };

};
