(function($) {

    'use strict';

    var CheckTaxIdentificationExists = function(element, options) {
        this.$element   = $(element);
        this.options    = options;

        this.page = this.$element.data('checkTaxIdentificationExists');
        this.originalTaxIdentification = '';
    };

    CheckTaxIdentificationExists.DEFAULTS = {
        'messages' : {
            'required' : 'Campo obrigatório',
            'tax-identification' : {
                 'invalid' : 'CPF inválido.',
                 'exists' : 'Este CPF já está cadastrado'
            }
        }
    };

    // saves a temporary copy so only checks if the customer try to change his tax-identification
    CheckTaxIdentificationExists.prototype.saveCachedTaxIdentification = function() {
        this.originalTaxIdentification = this.$element.val();
    };

    CheckTaxIdentificationExists.prototype.validate = function() {
        window.ParsleyUI.removeError(this.$element.parsley(), 'tax-identification-exists');

        if (this.$element.val().isCPF() === false) {
            return this.addError(this.options.messages['tax-identification'].invalid);
        }
        if (this.$element.val() != this.originalTaxIdentification) {
            this.load();
        }
    };

    CheckTaxIdentificationExists.prototype.load = function() {
        var params = {
            'tax' : this.$element.val(),
            'page' : this.page
        };

        $.getJSON('/checkout/index/CheckTaxIdentificationExist/', params, $.proxy(this, 'loaded'));
    };

    CheckTaxIdentificationExists.prototype.loaded = function(data) {
        if (data.response) {
            this.addError(this.options.messages['tax-identification'].exists);
        }
    };

    CheckTaxIdentificationExists.prototype.addError = function(message) {
        this.$element.removeClass('is-valid').addClass('is-invalid');
        window.ParsleyUI.addError(this.$element.parsley(), 'tax-identification-exists', message);
    };

    $.fn.checkTaxIdentificationExists = function(option, args) {
        return this.each(function () {
            var $this   = $(this);
            var data    = $this.data('dft.check-tax-identification-exists');
            var options = $.extend({}, CheckTaxIdentificationExists.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.check-tax-identification-exists', (data = new CheckTaxIdentificationExists(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('blur', '[data-check-tax-identification-exists]', function(e) {
        $(this).checkTaxIdentificationExists('validate');
    });

    $(document).ready(function(e) {
        $('[data-check-tax-identification-exists]').checkTaxIdentificationExists('saveCachedTaxIdentification');
    });

})(jQuery);
