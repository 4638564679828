 var addressId = "";
 var deleteUrl = "";

$(document).on('click', '[data-delete-address]', function(event) {
    event.preventDefault();
    addressId = $(this).data('delete-address');
    deleteUrl = $(this).data('delete-href');
});

$(document).on('click','[data-confirm-delete-address]',function() {
    $('.modal').modal('close');
    $.get(deleteUrl,function(data){
        $('[data-address='+addressId+']').fadeOut( function() { $(this).remove(); });
    });
});
