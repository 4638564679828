(function ($) {

    'use strict';

    var Carousel = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.pull = 0;
        
        this.type = this.$element.hasClass('is-vertical') ? new Vertical(this.$element) : new Horizontal(this.$element);
        this.setup();
        this.bind();
        
        this.$element.find('.carousel-items').contents().filter(function() {
            return this.nodeType === 3;
        }).remove();
        
        this.$element.addClass('is-loaded');
    };

    Carousel.DEFAULTS = {};

    Carousel.prototype.setup = function() {
        this.type.setup();
        this.count = this.$element.find('.carousel-item').length;
        this.visible = this.type.visible();
    };

    Carousel.prototype.bind = function() {
        this.type.bind(this);
    };

    Carousel.prototype.next = function() {
        this.setup();
        this.pull = Math.min(this.pull + 1, this.count - this.visible);
        if (this.pull < 0) this.pull = 0;
        this.update();
    };

    Carousel.prototype.previous = function() {
        this.setup();
        this.pull = Math.max(this.pull - 1, 0);
        this.update();
    };

    Carousel.prototype.update = function() {
        this.$element.find('.carousel-previous, .carousel-next').removeAttr('data-disabled');
        if (this.pull == 0 && this.pull >= (this.count - this.visible)) {
            this.$element.find('.carousel-previous').addClass('is-hidden');
            this.$element.find('.carousel-next').addClass('is-hidden');
        } else {
            if (this.pull == 0) {
                this.$element.find('.carousel-previous').removeClass('is-hidden');
                this.$element.find('.carousel-previous').attr('data-disabled', true);
            }
            if (this.pull >= (this.count - this.visible)) {
                this.$element.find('.carousel-next').removeClass('is-hidden');
                this.$element.find('.carousel-next').attr('data-disabled', true);
            }
        }
        this.type.update(this.pull);
    };

    var Horizontal = function(element) {
            this.$element = $(element);
    };

    Horizontal.prototype.setup = function() {
        this.itemSize = this.$element.find('.carousel-item:eq(0)').outerWidth();
        this.itemSizePercent = (this.itemSize * 100) / this.$element.find('.carousel-overflow').innerWidth();
    };

    Horizontal.prototype.bind = function(carousel) {
        this.$element.on('swipeleft', $.proxy(carousel, 'next'));
        this.$element.on('swiperight', $.proxy(carousel, 'previous'));
        this.$element.on('movestart', function(e) {
            // If the movestart is heading off in an upwards or downwards
            // direction, prevent it so that the browser scrolls normally.
            if ((e.distX > e.distY && e.distX < -e.distY) ||
                    (e.distX < e.distY && e.distX > -e.distY)) {
                    e.preventDefault();
            }
        });
    };

    Horizontal.prototype.visible = function() {
        return Math.ceil(this.$element.find('.carousel-overflow').innerWidth() / this.itemSize);
    };

    Horizontal.prototype.update = function(pull) {
        var _this = this;
        if (_this.$element.parents('slideshow-fade')){
            var selected =  $('.slideshow-item' )[ pull ];
            _this.$element.find('.slideshow-item').removeClass('is-active').addClass('is-inactive');
            setTimeout(function() {
                _this.$element.find('.carousel-items').css('left', (pull * _this.itemSizePercent * -1) + '%');
                $( '.modal-dialog .carousel').find( selected ).removeClass('is-inactive').addClass('is-active');
            }, 400);
        } else {
            _this.$element.find('.carousel-items').css('left', (pull * _this.itemSizePercent * -1) + '%');
        }
    };

    var Vertical = function(element) {
        this.$element = $(element);
    };

    Vertical.prototype.setup = function() {
        this.itemSize = this.$element.find('.carousel-item:eq(0)').outerHeight();
        this.$element.find('.carousel-overflow').css('height', this.$element.find('.carousel-overflow').innerHeight());
        this.itemSizePercent = (this.itemSize * 100) / this.$element.find('.carousel-overflow').innerHeight();
    };

    Vertical.prototype.bind = function(carousel) {
        this.$element.on('swipeup', $.proxy(carousel, 'next'));
        this.$element.on('swipedown', $.proxy(carousel, 'previous'));
    };

    Vertical.prototype.visible = function() {
        return Math.floor(this.$element.find('.carousel-overflow').innerHeight() / this.itemSize);
    };

    Vertical.prototype.update = function(pull) {
        this.$element.find('.carousel-items').css('top', (pull * this.itemSizePercent * -1) + '%');
    };

    $.fn.carousel = function (option) {
        
        return this.each(function () {
        var $this = $(this);
        var data = $this.data('dft.carousel');
        var options = $.extend({}, Carousel.DEFAULTS, $this.data(), typeof option == 'object' && option);

        if (!data) $this.data('dft.carousel', (data = new Carousel(this, options)));
            if (typeof option == 'string') data[option]();
        });
    };

    $(document).on('click', '.carousel-next', function(e) {
        e.preventDefault();
        $(e.currentTarget).parent('.carousel').carousel('next');
    });

    $(document).on('click', '.carousel-previous', function(e) {
        e.preventDefault();
        $(e.currentTarget).parent('.carousel').carousel('previous');
    });

    $(document).on('touchstart', '.carousel', function(e) {
        $(e.currentTarget).carousel();
    });

    try {
        document.querySelectorAll('body:nth-child(0)');
    } catch (e) {
        $('.carousel').carousel();
    }

})(jQuery);
