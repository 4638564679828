(function($) {

    'use strict';

    var AddToCartForm = function(element, options) {
        this.$element = $(element);
        this.$elementModal = this.$element.find(".modal-size-select");
        this.$template = this.$element.find('[data-sizes-item]').clone();
        this.$templateModal = this.$elementModal.find('[data-sizes-list-item]').clone();
        this.options = options;
        this.$templateAB = this.$element.find(".radio-button-block .radio-button-size-block:eq(0)").clone()
    };

    AddToCartForm.DEFAULTS = {
        ajax: false,
        dataType: 'json',
        loadingText: 'Loading...',
        successText: 'Success',
        showSizeReminder: false
    };

    AddToCartForm.prototype.init = function(e) {
        this.validate();

        if (!this.isValid()) {
            e.preventDefault();
        }

        if (this.isValid() && this.options.ajax) {
            this.add();
            e.preventDefault();
        } else if(this.isValid() && dft.dataLayer && dft.dataLayer[2]) {
            var selectedSize = $('[name="sku"] option:selected').text();
            var cartObj = {
                item: {
                    sku_simple: dft.dataLayer[2].sku || '',
                    name: dft.dataLayer[2].productName,
                    brand_name: dft.dataLayer[2].brand || '',
                    quantity : 1,
                    size: selectedSize,
                    price: dft.dataLayer[2].price
                }
            };
            $(document).trigger('dft.add-to-cart-form-submit-to-cart-page', cartObj);
        }
    };

    AddToCartForm.prototype.validate = function() {
        var isInvalid = !this.isValid();

        this.$element.toggleClass('is-invalid', isInvalid);
        this.$element.find('.add-to-cart-form-sizes').toggleClass('is-invalid', isInvalid);
        this.$element.find('#selection-box-radio-button').toggleClass('is-invalid', isInvalid);
    };

    AddToCartForm.prototype.isValid = function() {
        return (
            this.$element.parent().find('input[name="sku"]').val().length > 0 &&
            this.$element.parent().find('[name="sku"]').val().length > 0
        ) || $('.radio-button-size-block.selected').length === 1;
    };

    AddToCartForm.prototype.change = function() {
        var value = this.$element.find('[data-sizes-item]').filter(":selected").data("stockMessage");
        var sizeMessage = this.$element.find('[data-size-message]');
        if (typeof value == "undefined") {
           sizeMessage.html("");
        } else {
            sizeMessage.html("("+value+")");
        }
    };

    AddToCartForm.prototype.orderByname = function(product_a, product_b)
    {
        if (!isNaN(product_a.name)) {
            return product_a.name - product_b.name;
        }
    };

    AddToCartForm.prototype.sizes = function(sizes) {
        var $options = this.$element.find('[data-sizes]');
        var productsCount = typeof sizes.length != 'undefined' ? sizes.length : 0;
        var position = 0;
        var tAB = this.$element.find(".radio-button-block");

        sizes.sort(this.orderByname);

        var url_string = document.location.href;
        var url = new URL(url_string);
        var selectedSize = url.searchParams.get("size");

        for (var item in sizes) {
            this.template(sizes[item], selectedSize).insertBefore($options.find("[data-size-reminder]"));
            tAB.append(this.templateAB(sizes[item], selectedSize));
            if (sizes[item].stock == 0) {
                productsCount--;
            } else {
                this.templateModal(sizes[item]).insertBefore(this.$elementModal.find('[data-size-list-reminder]'));
                position = item;
            }
        }

        this.$elementModal.find('[data-sizes-list-item]:first').remove();

        if (productsCount == sizes.length) {
            $options.find("[data-size-reminder]").remove();
            $options.find('[data-size-list-reminder]').remove();
        } else {
            this.$element.trigger('sizereminder');
        }

        if (productsCount == 1) {
            this.$element.find('[data-sizes-item]:first').remove();
            this.change();
            this.$element.find('li.radio-button-size-block[data-value="' + sizes[position].sku + '"] input[type=radio]').change();
        }

        if (productsCount == 0) {
            $options.attr('disabled', 'disabled');
            $('#product-delivery-clock').addClass('hide');
        }
    };

    AddToCartForm.prototype.triggerSizereminder = function () {
        this.$element.trigger('sizereminder');
    };

    AddToCartForm.prototype.template = function(item,selectedSize) {
        var result = this.$template.clone();

        result.attr('value', parseInt(item.stock, 10) ? item.sku : '');

        if (item.name == selectedSize) {
            result.attr('selected','selected');
        }

        if (item.name.length > 2) {
            item.stock_message = item.stock_message.replace(/\ itens/, '');
        }

        result.text(item.name);
        result.attr('data-stock-message', item.stock_message);

        if (item.stock == 0) {
            result.attr('disabled', 'disabled');
            result.text(item.name + " (Esgotado)");
        }

        return result;
    };

    AddToCartForm.prototype.templateAB = function (item, selectedSize) {
        var result = this.$templateAB.clone();
        var hasSoldOut = true;
        var _element = this.$element;

        result.attr('hidden', false);

        if (item.name == selectedSize) {
            this.$element.find(".radio-button-block li").each( function(key, el) {
                el.classList.remove('selected');
            });
            this.$element.find('li[data-value="' + item.sku + '"]').addClass('selected');
        }

        result.find('input[type=radio]').change(function (e) {
            _element.find("[name='sku']").each( function() {
                $(this).attr('value', item.sku);
            });
            _element.find(".radio-button-block li").each( function(key, el) {
                el.classList.remove('selected');
            });
            _element.find('li[data-value="' + item.sku + '"]').addClass('selected');

            $('#seller-text[data-field=delivery_type_html]').html(item.delivery_type_html);

            if (item.display_stock) {
                _element.find('#form-message[data-field=stock_message] span').text(item.stock_message);
                _element.find('#form-message[data-field=stock_message]').css('display', 'flex');
            } else {
                _element.find('#form-message[data-field=stock_message] span').text('');
                _element.find('#form-message[data-field=stock_message]').css('display', 'none');
            }

            _element.find('#form-forgot-message').css('display', 'none');

            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    'event': 'label_ga3',
                    'category': 'productPage',
                    'action': 'sizeSelectionClick',
                    'label': item.sku + '/' + item.name
                });
            }

        });

        if ($('#stock-available[data-sold-out]').length) {
            hasSoldOut = false;
        }

        result.attr("data-value", parseInt(item.stock, 10) ? item.sku : "");
        result.find("[data-field=name]").text(item.name);
        item.display_stock && result.find("[data-field=stock_message]").text(item.stock_message);

        if (hasSoldOut) {
            result.find("[name=size_radio_button]").attr("disabled", 0 == item.stock);
            result.find(".selectbox-option-stock").toggleClass("is-sold-out", 0 == item.stock);
        } else if (0 == item.stock) {
            result = "";
        }

        if (this.$chosenSize == item.name && item.stock != 0) {
            result.find("[name=size_radio_button]").prop("checked", true);
            _element.find('[name="sku"]').attr("value", item.sku);
        }

        return result;
    };

    AddToCartForm.prototype.templateModal = function(item,selectedSize) {
        var result = this.$templateModal.clone();

        result.attr('data-value', parseInt(item.stock, 10) ? item.sku : '');

        // if (item.name == selectedSize) {
        //     result.attr('selected','selected');
        // }

        if (item.name.length > 2) {
            item.stock_message = item.stock_message.replace(/\ itens/, '');
        }

        result.text(item.name);
        result.attr('data-stock-message', item.stock_message);

        if (item.stock == 0) {
            result.attr('disabled', 'disabled');
            result.text(item.name + " (Esgotado)");
        }

        return result;
    };

    AddToCartForm.prototype.add = function() {
        if (this.isCartPage()) {
            this.submitOnEmptyCart();
            this.cartAdd();
            return;
        }

        this.ajax();
    };

    AddToCartForm.prototype.ajax = function() {
        var url = this.$element.attr('action');
        var sku = this.$element.find('[name=sku]').val().length > 0 ? this.$element.find('[name=sku]').val() : this.$element.find('[name=sku]').attr('value');

        url += '?sku_simple=' + sku;

        this.$element.find('.add-to-cart-form-button').blur();
        this.triggerLoadingEvent();

        $.ajax({
            url: url,
            type: this.$element.attr('method'),
            dataType: this.options.dataType,
            data: this.$element.serialize()
        })
        .success($.proxy(this, 'response'))
        .error($.proxy(this, 'response'));
    };

    AddToCartForm.prototype.response = function(response) {
        if (response.status === 200)  {
            this.triggerSuccessEvent();

            $(document).trigger('dft.add-to-cart-form.success', response);
        } else {
            this.redirectToCart();
        }
    };

    AddToCartForm.prototype.submitOnEmptyCart = function() {
        if ($('[data-cart-item]').length === 0) {
            this.$element.submit();
        }
    };

    AddToCartForm.prototype.cartAdd = function() {
        var sku = this.$element.find('[name=sku]').val().length > 0 ? this.$element.find('[name=sku]').val() : this.$element.find('[name=sku]').attr('value');
        var params = {
            'sku_simple' : sku,
            'b2014' : 1
        };

        this.loading();

        $(document).trigger('dft.cart-add.add', [params]);
        $(document).one('dft.cart-add.item-added', $.proxy(this, 'success'));
    };

    AddToCartForm.prototype.triggerLoadingEvent = function() {
        this.$element.trigger('loading');
        this.loadingDialog(false, false, this.options.loadingText);
    };

    AddToCartForm.prototype.triggerSuccessEvent = function() {
        if (this.$element.attr('id') == "add-to-cart") {
            this.$element.trigger('success');
        }

        this.loadingDialog(false, true, this.options.successText);
    };

    AddToCartForm.prototype.loadingDialog = function(isLoaded, isSuccess, message) {
        var loadingDialog = this.$element.find('.loading-dialog');

        loadingDialog.toggleClass('is-loaded', isLoaded);
        loadingDialog.toggleClass('is-success', isSuccess);

        this.$element.find('.loading-message').text(message);

        var removeLoadingIndicator = function() {
            loadingDialog.addClass('is-loaded');
            loadingDialog.removeClass('is-success');
            loadingDialog.removeClass('loading');
        };

        setTimeout(removeLoadingIndicator, 5000);
    };

    AddToCartForm.prototype.handleLoading = function() {
        this.$element.find('[data-add-to-cart-form-button]').attr('disabled', 'disabled');
    };

    AddToCartForm.prototype.handleSuccess = function() {
        this.$element.find('[data-add-to-cart-form-button]').attr('disabled', false);
    };

    AddToCartForm.prototype.isCartPage = function() {
        return '/cart/' === window.location.pathname;
    };

    AddToCartForm.prototype.redirectToCart = function() {
        window.location.href = '/cart/';
    };

    $.fn.addToCartForm = function(option, sizes) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('dft.addtocart');
            var options = $.extend({}, AddToCartForm.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.addtocart', (data = new AddToCartForm(this, options)));
            if (typeof option == 'string') data[option](sizes);
        });
    };

    $(".modal-size-select, .modal-size-select .modal-close").on('click', function(){
        $(".modal-size-select .modal-box").slideUp({ duration: 300, queue: false });
        $(".modal-size-select").fadeOut({ duration: 200, queue: false });
        $("body").removeClass("modal-open");
    });

    $("[data-sizes-list]").on('click', '[data-sizes-list-item]', function(e){
        var val = $(this).data('value');
        $(this).parents('form').find('[name="sku"]').val(val);
        $('li.radio-button-size-block[data-value="' + val + '"] input[type=radio]').change();
        $(this).closest('.add-to-cart-form').addToCartForm('change');
        $(this).closest('.add-to-cart-form').submit();
    });


    $(document).on('change', '[data-sizes]', function() {
        if($(this).val() == 'size-reminder') {
            $('[data-notify-when-back]').click();
            $('[data-size-message]').html("");
            $('[data-sizes] option:first-child').attr("selected", "selected");
        } else {
            $(this).closest('.add-to-cart-form').addToCartForm('change');
        }
    });

    $(document).on('change', '.add-to-cart-form-sizes', function() {
        $(this).parents('form').find('[name="sku"]').val($(this).val());
    });


    $(document).on('change', '.add-to-cart-form.is-invalid .add-to-cart-form-sizes', function() {
        $(this).closest('.add-to-cart-form').addToCartForm('validate');
    });

    $(document).on('submit', '.add-to-cart-form', function(e) {
        $(this).addToCartForm('init', e);
    });

    $(document).on('loading', '.add-to-cart-form', function(e) {
        $(this).addToCartForm('handleLoading');
    });

    $(document).on('click', '#form-size-not-found', function(){
        $('[data-notify-when-back]').click();
    });

})(jQuery);
