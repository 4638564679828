(function($) {

    'use strict';

    var OrderDetailAction = function(element) {
        this.$element = $(element);
    };
    OrderDetailAction.prototype = new window.dft.ActionController();

    OrderDetailAction.prototype.run = function() {
        var message = this.$element.data('toggleMessage');
        this.$element.toggleClass('up');
        this.$element.prev('[data-product-item-more-info]').toggleClass('hello');
        this.$element.data('toggleMessage', this.$element.text());
        this.$element.text(message);
    };

    $(document).on('click', '[data-show-more]', function(event) {
        var element = $(this);
        (new OrderDetailAction(element)).run();
    });

})(jQuery);
