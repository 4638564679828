(function () {

    var topic;
    var problem;
    var problemDetail;
    var problemDetailId;
    var labelSelected;
    var excludeAccount;
    var outrosActive;

    $(document).on('click', '.mainContent-step-content', function (e) {
        e.stopPropagation();
    });

    //section 1: reasons
    $('input:radio[name="reason"]').change(function(e) {
        $('#step_1').addClass('hide');
        $('#step_2_title').removeClass('hide');
        $('#step_2').removeClass('hide');
    });

    // section 2: topics
    $(document).on('click', '.mainContent-topics-item', function (e) {
        $('#step_2_title').addClass('hide');
        $('#step_2').addClass('hide');
        $('#step_3').removeClass('hide');

        topic = e.currentTarget.id;
        var topicProblems = topic + '_problems';
        var topicProblemsDetails = topicProblems + '_details';

        var topicClass = '.' + topic;
        var topicProblemsId = '#' + topicProblems;
        var topicProblemsDetailsId = '#' + topicProblemsDetails;

        setItemSelected(e);
        $('#support_email').removeClass('hide').addClass('inactive');
        $('.custom-select').addClass('hide').removeClass('active').prop('disabled', true);
        $(topicProblemsDetailsId).find('select:first').removeClass('hide');
        $(topicProblemsId).removeClass('hide');
    });

    // section 3: problems
    $('input:radio[name="problem"]').change(function(e) {
        $('#support_email').addClass('inactive');
        $('.custom-select').addClass('hide').removeClass('active');
        $('.mainContent-support, .problem-required').addClass('hide');
        $('.mainContent-problems-detail').removeClass('hide');
        $('.custom-radio > input[type="radio"]').removeClass('error');
        $('input[name="FaqContactForm[subject]"]').val(labelSelected.toUpperCase());

        problem = e.currentTarget.id;
        problemDetail = problem + '_detail';
        problemDetailId = '#' + problemDetail;

        if($(document).find(problemDetailId).length !== 0) {
            $('#support_email').removeClass('hide');
            $(problemDetailId).removeClass('hide').prop("disabled", false);
            $(problemDetailId + ' > option:first').prop("selected", true);
        } else {
            $('.mainContent-problems-detail').addClass('hide');
            if($(document).find('#' + problem + '_default').length !== 0) {
                $('#' + problem + '_default').removeClass('hide');
            } else {
                $('#support_email').removeClass('hide').removeClass('inactive');
            }
        }
    });

    $(document).ready(function(){
        excludeAccount = $("#topic_other").data('exclude');
        outrosActive = $("#topic_other").data('outros');
        valideExcludeAccountIsActive();
        valideOutrosIsActive();
    });

    $(document).on('change', '.mainContent-problems-detail select', function(e) {
        $('#support_email').removeClass('inactive');
        $('.custom-select').removeClass('error');
        $('.detail-required').addClass('hide');
        $('.mainContent-support').addClass('hide');
        $('.mainContent-problems-detail select').addClass('active');
        if($(document).find('#' + this.value).length !== 0) {
            $('#' + this.value).removeClass('hide');
        } else {
            $('#support_email').removeClass('hide');
        }
        verifySelectForDeleteAccount(e);
    });

    function verifySelectForDeleteAccount(event) {
        var getSeletTag = event.currentTarget.id;
        if (getSeletTag == "topic_other_problem_1_detail") {
            var selectOption = $('#topic_other_problem_1_detail').val();
            if (selectOption == "support_email") {
                var textDeleteAccountSelected = 'OUTROS - Exclusão de Cadastro';
                $('input[name="FaqContactForm[subject]"]').val(textDeleteAccountSelected);
            }else{
                $('input[name="FaqContactForm[subject]"]').val(labelSelected.toUpperCase());
            }
        }
    }

    // send email
    $(document).on('click', '.link-support-email', function (e) {
        $('.mainContent-support').addClass('hide');
        $('#support_email').removeClass('hide').removeClass('inactive');
    });

    $(document).on('submit', '#form-faq-contact', function(e) {
        if(hasProblemChosen()) {
            var reasonText = $("input[name='reason']:checked").next('label').text();
            var problemText = $('.custom-radio > input[type="radio"]:checked').siblings('label').text();
            var problemDetailText = $('.custom-select.active').not('.hide').find(':selected').text();
            var messageText = $('#message').val();
            var messageFullText = $('#fullMessage');

            messageFullText.val("MOTIVO: " + reasonText + "\n" +
                "PROBLEMA: "  + problemText + "\n" +
                "DETALHE: " + problemDetailText + "\n" +
                "MENSAGEM: \n" + messageText
            );
            return;
        }
        e.preventDefault();
    });

    $(document).on('focus', '#form-faq-contact :input:not([type=hidden])', hasProblemChosen);

    function hasProblemChosen() {
        if($('#' + topic + '_problems').length === 0 ) {
            return true;
        }

        if(problem && $('#' + problem + '_detail').length === 0 ) {
            return true;
        }

        if($('.custom-radio > input[type="radio"]:checked').length === 0) {
            $('.custom-radio > input[type="radio"]').addClass('error');
            $('.problem-required').removeClass('hide');
            $(document).scrollTop(200);
            return false;
        } else if($('.custom-select.active').not('.hide').find(':selected').length === 0) {
            $('.custom-select').addClass('error');
            $('.detail-required').removeClass('hide');
            $(document).scrollTop(200);
            return false;
        }
        return true;
    }

    function debug() {
        console.log('topic: ', topic);
        console.log('problem: ', problem);
        console.log('problemDetail: ', problemDetail);
        console.log('problemDetailId: ', problemDetailId);
    }

    function setItemSelected(e) {
        var imageSelected = $(e.currentTarget).find('img').attr('src');
        var textSelected = $(e.currentTarget).find('p').text();
        labelSelected = $(e.currentTarget).find('label').text();

        $('#icon_selected').attr('src', imageSelected);
        $('#label_selected').text(labelSelected);
        $('#text_selected').text(textSelected);
        $('input[name="FaqContactForm[subject]"]').val(labelSelected.toUpperCase());
        $('.breadcrumb2').append('<li class="breadcrumb2-item"><a class="breadcrumb2-item-link">' + labelSelected + '</a></li>')
    }

    function valideExcludeAccountIsActive() {
        if (excludeAccount != '1') {
            return;
        }

        $("#topic_other")[0].click();
        $("#topic_other_problem_1").prop("checked", true);
        $("#topic_other_problem_1").trigger("change");
        $("#order-nr-exclude-account").hide();
        $("#topic_other_problem_1_detail").val("support_email");
    }

    function valideOutrosIsActive() {
        if (outrosActive != '1') {
            return;
        }

        $("#topic_other")[0].click();
    }
})();